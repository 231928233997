import '@app/css/app.css';

/**
 * Bootstrap.
 */
import '@shared/scripts/bootstrap';
import './bootstrap';

/**
 * Day.js.
 */
import Dayjs from '@shared/plugins/dayjs';

/**
 * Modals.
 */
import Modals from '@shared/plugins/modals';

/**
 * Lottie.
 */
import Lottie from '@shared/plugins/lottie';

/**
 * Podcast player.
 */
import PodcastPlayer from '@shared/plugins/podcast-player';
import LivePlayer from '@shared/plugins/live-player';

/**
 * Ziggy.
 */
// @ts-expect-error Ziggy routes.
import { Ziggy as ZiggyRoutes } from '@app/routes';
import ZiggyVue, { type ZiggyConfig } from '@shared/plugins/ziggy';

/**
 * Processing static assets with Vite.
 */
import.meta.glob(['../assets/**']);

/**
 * Vue.
 */
import { createSSRApp, h, type DefineComponent } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

void createInertiaApp({
  progress: {
    color: '#977cee',
  },
  title: (title: string) => (title ? `${title} | HULiGENNEM` : 'HULiGENNEM'),
  resolve: async (name: string) => {
    // Get page based on view name.
    const page = await resolvePageComponent<DefineComponent>(`../views/${name.replace('app/views/', '')}.vue`, import.meta.glob<DefineComponent>('../views/**/*.vue'));

    // Set page layout as default, if none has been provided.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (page.default.layout === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      page.default.layout = (await import('@app/views/Layout.vue')).default;
    }

    return page;
  },
  setup: ({ el, App, props, plugin }) => {
    // Create Vue SSR App instance.
    const app = createSSRApp({
      render: () => h(App, props),
    });

    /**
     * Register our Vue plugins with our Vue application.
     * The order of registering should not matter.
     */
    app.use(plugin)
      .use(ZiggyVue, ZiggyRoutes as ZiggyConfig)
      .use(Dayjs)
      .use(Modals)
      .use(Lottie)
      .use(PodcastPlayer, {
        cacheKey: 'app',
      })
      .use(LivePlayer);

    /**
     * Mount our Vue application to the root DOM element.
     */
    app.mount(el);
  },
});
